.ant-form-item-label {
    white-space: normal !important;
}
.AppObjectsWrapper {
    width: 100%;
    overflow: auto;
}
.AppObjectsTable {
    width: 100%;
    border-radius: 2px 2px 0 0;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #f3f3f3;
    font-size: 12px;
}
.AppObjectsTable .ant-input,
.AppObjectsTable .ant-input-number-input,
.AppObjectsTable .ant-picker-input > input
{
    font-size: 12px;
}
.AppObjectsTable > thead > tr:first-child th:first-child {
    border-top-left-radius: 2px;
}

.AppObjectsTable > thead > tr:first-child th:last-child {
    border-top-right-radius: 2px;
}
.QsmTableHead > tr > th {
    position: relative;
    color: #000000d9;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background .3s ease;
    white-space: nowrap;
}
.AppObjectsTableHead > tr > th.colFixed,
.AppObjectsTable.colFixed > tbody > tr > td:last-child {
    position: sticky;
    right: 0;
    border: 1px solid #f0f0f0;
    z-index: 1;
}
.AppObjectsTable > tbody > tr > td:last-child {
    border: 1px solid #f0f0f0;
    background-color: #fff;
}

.AppObjectsTable th, .AppObjectsTable td {
    border-right: 1px solid #f0f0f0;
    border-bottom: 1px solid #f0f0f0;
    position: relative;
    padding: 10px 5px;
    overflow-wrap: break-word;
}

.AppObjectsTableHead > tr > th {
    position: relative;
    color: #000000d9;
    background: #fafafa;
    border-bottom: 1px solid #f0f0f0;
    transition: background .3s ease;
}

.ExtradionTable {

    border-radius: 2px 2px 0 0;
    border-collapse: separate;
    border-spacing: 0;
    border: 1px solid #f3f3f3;
}
.ExtradionTable tr td:first-child {
    font-weight: bold;
}
.ExtradionTable tr td {
    padding: 5px 15px;

}
.ExtradionTable .ant-form-item {
    margin-bottom: 0;
}
.ExtradionTable .ant-form-item-label > label {

    line-height: 1;
}