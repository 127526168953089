.appLayout {
    min-height: 100vh !important;
}
.appLayout .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
}

.appLayout .trigger:hover {
    color: #1890ff;
}

.appLayout .logo {
    height: 32px;
    margin: 16px;
    background: rgba(255, 255, 255, 0.3);
}

.site-layout .site-layout-background {
    background: #fff;
}
.appLayout p {
    margin-bottom: 0;
}
.site-layout .ant-layout-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.site-layout .ant-layout-header .ant-dropdown-trigger {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 24px;
}
.site-layout .ant-layout-header .ant-dropdown-trigger .ant-avatar {
    margin-right: 10px;
}