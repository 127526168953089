.login-form-wrapper {
    height: 100vh;
}
.login-form-forgot {
    float: right;
}
.ant-col-rtl .login-form-forgot {
    float: left;
}
.login-form-button {
    width: 100%;
}
.login-form-avatar {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
}